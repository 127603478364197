@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');

@font-face {
    font-family: "Chava-Reg";
    src: local("Chava-Reg"), url("Chava-Regular.ttf") format("truetype");
}

.apply-font-chava-reg {
    font-family: Chava-Reg, serif;
}

/*#root {*/
/*    overflow: hidden;*/
/*}*/
