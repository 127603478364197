.userDropdown {
  .ant-dropdown-menu {
    padding: 0;
    .ant-dropdown-menu-item {
      border-bottom: 1px solid #f7f7f7 !important;
      /*иначе никак сори*/
    }
  }
}
.dropdown-notification .ant-notification-notice-message {
  margin-bottom: 0px !important;
}
