
:root {
    --primary-gradient: linear-gradient(270deg, #6E27F8 -85.22%, #20D9C0 164.99%);
    --primary-gradient-hover: linear-gradient(270deg, #9A66FF -85.22%, #36FDE2 164.99%);
    --primary-gradient-active: linear-gradient(270deg, #4100C0 -85.22%, #01B79E 164.99%);
}

.ant-select-dropdown {
    width: auto !important;
}

.ant-empty .ant-empty-description {
    color: #000000E0;
}

.htmlReversed {
    direction: rtl;

    * {
        writing-mode: horizontal-tb;
    }
}

.htmlNormal {
    direction: ltr !important;
}

html,
body {
    padding: 0;
    margin: 0;
}

.border-gradient1 {
    display: flex;
    flex-direction: column;
    padding: 2px;
    gap: 24px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(0deg, rgb(28, 28, 39) 100%, rgb(28, 28, 39) 100%) padding-box padding-box,
    linear-gradient(150deg, rgb(82, 151, 255) 0%, rgba(82, 151, 255, 0) 55%) border-box border-box;
    border-radius: 16px;
    width: 32.66%;
}

.grounded-radiants {
    position: relative;
    border: 4px solid transparent;
    border-radius: 16px;
    background: linear-gradient(orange, violet);
    background-clip: padding-box;
    padding: 10px;
}

.grounded-radiants::after {
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    background: linear-gradient(red, blue);
    content: '';
    z-index: -1;
    border-radius: 16px;
}

.ant-btn-primary {
    border: none;
    background: var(--primary-gradient);
    transition: all 1s ease;
    box-shadow: none;
}

.ant-btn-primary:hover {
    background: var(--primary-gradient-hover);
}

.ant-btn-primary:active {
    background: var(--primary-gradient-active);
}

.ant-btn-primary:disabled {
    border: 1px solid #00000026 !important;
    background: #0000000A !important;
}

.filters-counter {
    width: 20px;
    height: 16px;
    color: #fbfbff;
    background: linear-gradient(75.86deg, #088473 6.84%, #0ec6ad 107.11%);
    line-height: 15px;
    font-size: 12px;
    border-radius: 20px;
}

.ant-layout .ant-layout-sider {
    background: transparent;
}

/* Breadcrumb  */
/*.ant-breadcrumb {*/
/*    font-family: Chava-Reg, serif;*/
/*}*/


/*!* Modal *!*/


.ant-modal .ant-qrcode {
    background-color: transparent;
}

.ant-modal .ant-modal-header {
    background: transparent;
}

.ant-modal .ant-modal-content {
    border-radius: 16px;
}

.ant-modal-title {
    background: transparent;   
}

.ant-modal-header {
    background-color: transparent !important;
}


.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 32px !important;
}

/*.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    line-height: 17px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: #FBFBFF;*/
/*}*/

/*.ant-select {*/
/*    color: white;*/
/*}*/

/*.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-useExchangePointState.ts {*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    line-height: 17px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: #FBFBFF;*/
/*}*/

/*.ant-select-selector {*/
/*    border: none !important;*/
/*    background-color: rgba(0, 0, 0, 0) !important;*/
/*    padding-top: 2px !important;*/
/*}*/

/*.ant-select-single.ant-select-open .ant-select-selection-item {*/
/*    color: #FBFBFF!important;*/
/*}*/

/*.ant-select .ant-select-selection-placeholder {*/
/*    color: #767687;*/
/*}*/

/*.ant-select-single .ant-select-selector {*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    line-height: 17px;*/
/*    color: #FBFBFF;*/
/*}*/

/*!* Modal *!*/

.ant-modal {
    padding-bottom: 0;
}

.ant-modal .ant-modal-content {
    padding: 32px;
}

.ant-modal .ant-modal-header {
    background: none;
}

.ant-modal .ant-modal-body {
    line-height: 15px;
}

/*.ant-modal {*/
/*    font-family: Chava-Reg, serif;*/
/*}*/

.ant-modal .ant-modal-title {
    margin-bottom: 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.ant-modal-footer > button {
    margin-inline-start: 0 !important;
}


.title-icon > svg {
    width: 48px;
    height: 48px;
}

.select-icon {
    width: 32px;
    height: 32px;
    background-color: #f3f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-icon-white {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.ant-select-dropdown .ant-select-item.ant-select-item-option {*/
/*    min-height: 56px;*/
/*}*/

/*.ant-select-selector .select-icon-white {*/
/*    width: 14px;*/
/*    height: 14px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

.ant-modal
.ant-select.ant-select-disabled
.ant-select-selector
.ant-select-selection-item
.ant-typography {
    color: #474755;
}

.select-label-style .ant-form-item-row .ant-form-item-label label {
    color: #474755;
}

.ant-typography {
    /*color: #6E27F8 !important;*/
}

/*.select-style .ant-select-selection-item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.select-style .select-icon>svg {*/
/*    width: 18px;*/
/*    height: 18px;*/
/*    background-color: #F3F5F7;*/
/*}*/

.input-number-in-card > div > input {
    background: #2b2b36;
    line-height: 120%;
    font-weight: 700;
    height: 48px !important;
}

.input-number-in-card > div > input:hover {
    background: #3f3f4b;
}

.input-number-in-card > div > input:focus-visible {
    background: #18181f;
}

.wallet-address-input-wrapper .ant-input-number-group-addon {
    background: #6e27f8 !important;
    cursor: pointer !important;

    border: none !important;
}

.input-number-in-card {
    line-height: 120%;
    font-weight: 700;
}

.block-with-box {
    position: relative;
}

.sds .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    max-width: 340px;
    min-height: 448px;
    left: 920px;
    top: 190px;
    background: #ffffff;
    border-left: 6px solid #d458ff;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 20px 24px rgba(20, 37, 63, 0.06);
}

.block-with-box .box::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 4px;
    width: 10px;
    height: 10px;
    background: #d458ff;
}

.block-with-box .box::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 16px;
    width: 10px;
    height: 10px;
    background: #d458ff;
}


.ant-dropdown-menu-item :hover {
}

.custom-skeleton-elements > div > ul {
    margin-bottom: 0;
}

.custom-skeleton-elements > div > ul > li {
    height: 48px !important;
}

.custom-skeleton-elements.filters {
    position: absolute;
    top: 100px;
}

.ant-dropdown-menu-item :hover {
}


/* FIXME: НЕ ТРОГАТЬ РАДИ ХРИСТА */

.ant-form-item-control-input-content .select-icon-white,
.ant-select-single .select-icon-white {
    width: 15px;
}


/* Stepper styles */

/*DOT STEPPER DONT TOUCH!!!! or be carefully*/
.ant-steps
.ant-steps-item-wait
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: #474755 !important;
    border: 0 solid red !important;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon {
    border: none !important;
}

.ant-steps
.ant-steps-item-process
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
}

.ant-steps
.ant-steps-item-finish
> .ant-steps-item-container
> .ant-steps-item-tail::after {
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
}

.ant-steps-item-finish
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon {
    width: 8px !important;
    height: 8px !important;
}

.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
    width: 12px !important;
    height: 12px !important;
    top: -2px;
    left: -2px;
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    width: 8px !important;
    height: 8px !important;
}

/*End DOT*/

.ant-steps-item-process .ant-steps-item-icon {
    font-size: 16px !important;
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
    color: #fbfbff !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    font-size: 16px !important;
    background: none !important;
    color: #fbfbff !important;
}

.ant-steps-item-process .progress-icon {
    display: inline-block;
    position: absolute;
    top: -4px;
    left: -20px;
    animation: rotate 1.5s linear infinite;
}

.ant-steps-item-process .progress-icon path {
    fill: url(#gradient);
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
    border-bottom-color: #474755;
    border-top-color: #474755;
}

b
tr
> :where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
> :where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
    .ant-picker-cell-range-hover-edge-end-near-range
  )::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-inline-end-color: #474755;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

tr
> :where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
> :where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  )::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-inline-start-color: #474755;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ant-steps-item-wait .ant-steps-item-icon {
    font-size: 16px !important;
    background-color: transparent !important;
    border: 1px solid #767687 !important;
}

.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    color: #767687 !important;
    background-color: transparent;
}

.ant-steps-item-tail::after {
    background-color: #474755 !important;
}

.ant-steps-item-icon {
    width: 32px !important;
    height: 32px !important;
}

.custom-stepper-icon {
    width: 30px !important;
    font-size: 16px;
}

.custom-stepper-icon.custom-stepper-icon-dark {
    width: 30px !important;
    color: #767687;
    font-size: 16px !important;
}

.ant-steps-icon .custom-stepper-icon-dark {
    font-size: 16px !important;
}

.custom-stepper-icon.custom-stepper-icon-light {
    width: 30px !important;
    color: #fbfbff;
}

/* Form item label */


:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
:where(.css-dev-only-do-not-override-16b0y2x).ant-picker-dropdown
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
    border-top-color: #474755;
    border-bottom-color: #474755;
}


/* Slider */
.ant-slider .ant-slider-rail {
    background-color: #767687 !important;
}

.ant-slider .ant-slider-track {
    background-color: #fbfbff !important;
}

.ant-slider .ant-slider-handle::after {
    background-color: #fbfbff !important;
    width: 16px !important;
    height: 16px !important;
    inset-block-start: -1px !important;
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
    background-color: #474755 !important;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
    background-color: #767687 !important;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after {
    background-color: #767687 !important;
    width: 16px !important;
    height: 16px !important;
    inset-block-start: -1px !important;
}


.ant-table-content table {
    border: 1px solid #00000026;
}


.ant-table-content table {
    border: 1px solid #00000026;
}

.ant-notification-notice-message {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
    display: none !important;
}

.ant-tooltip-input-lock {
    max-width: 325px;
}


.sidebar-menu ul.ant-menu-sub {
    background: #1b1b25 !important;
    padding: 4px 0 !important;
}

.sidebar-menu ul.ant-menu-sub .ant-menu-item-selected {
    background: var(--primary-gradient) !important;
    border-radius: 8px !important;
}

.notification-dropdown .ant-dropdown-menu {
    height: min(580px, 65vh);
    overflow: hidden scroll;
}
