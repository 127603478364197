.linkDropdown {
    border: 1px solid #F5F5F5;
    .ant-dropdown-menu > .ant-dropdown-menu-item{
    border-bottom: 1px solid #F5F5F5 !important;
}/*иначе никак сори*/
    .ant-dropdown-menu >.ant-dropdown-menu-item-selected {
        border-bottom: 1px solid #E6E6E6 !important;
        border-left: 3px solid transparent;
        background: linear-gradient(0deg, #fff,#fff) padding-box, linear-gradient(0deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box;
    }
    /*иначе никак сори*/
}
