/*.ant-menu > .ant-menu-item {*/
/*    width: 100% !important;*/
/*    margin: 0 !important;*/
/*    padding: 0 !important;*/
/*    !*border-left: 3px solid transparent;*!*/
/*    background: transparent;*/
/*}*/

/*.ant-menu-light:not(.ant-menu-horizontal).ant-menu > .ant-menu-item:not(.ant-menu-item-selected):active {*/

/*}*/

/*:where(.css-dev-only-do-not-override-br05hh).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{*/
/*    padding-left: 15px !important;*/
/*}*/

/*.ant-menu-submenu-title {*/
/*    padding-left: 0px !important;*/
/*}*/

/*:where(.css-dev-only-do-not-override-1jo4mgj).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{*/
/*    //background: transparent;*/
/*}*/

/*.ant-menu-item:not(.ant-menu-item-selected):active{*/

/*}*/


/*.ant-menu-inline .ant-menu-submenu-title{*/
/*    margin-inline: unset !important;*/
/*    width: 100% !important;*/
/*}*/
/*.ant-menu-sub{*/
/*    background-color: #29292B !important;*/
/*}*/

/*.ant-layout-sider {*/
/*    background-color: #29292B !important;*/
/*}*/


/*.ant-menu > .ant-menu-item-selected,*/
/*.ant-menu > .ant-menu-submenu-selected {*/
/*    box-sizing: border-box !important;*/
/*    border-radius: 8px;*/
/*}*/

/*.active-item {*/
/*    padding: 8px 24px;*/
/*    background: linear-gradient(-89.6deg, rgba(110, 39, 248, 1.00) 0%, rgba(32, 217, 192, 1.00) 100%);*/
/*    border-radius: 8px;*/
/*}*/

